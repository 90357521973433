<template>
  <div class="charge-main">
    <div class="me-top">
      <img src="@/assets/user.png" alt="user">
      <span>{{ user.username }}</span>
    </div>

    <div class="me-main">
      <div class="me-main-box">
        <div class="me-main-title">基本信息</div>
        <div class="me-main-txt">
          <p>注册账号 <span>{{ user.username }}</span></p>
          <p>注册时间 <span>{{ user.createtime }}</span></p>
        </div>
      </div>

      <div class="me-main-box">
        <div class="me-main-title">会员信息</div>
        <div class="me-main-txt">
          <p>会员有效期 <span>{{ user.starttime ? '永久' : '-' }}</span></p>
          <p>购 买 时 间 <span>{{ user.starttime ? user.starttime : '-' }}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../utils/charge.css'; // 引入样式文件

export default {
  name: 'ChargePage',
  components: {},
  data() {
    return {
      user: {}
    }
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
    if(localStorage.getItem('userId')){
      this.getUser()
    }else{
      this.$router.push('/');
    }
    
  },
  methods: {
    getUser() {
      this.$api.getUserInfo({ accountId: localStorage.getItem('userId') }).then((res) => {
        console.log(3333333,res.data)
        this.user = res.data
        this.user.createtime = this.user.createtime.slice(0, 10);
        this.user.starttime = this.user.starttime ? this.user.starttime.slice(0, 10) : ''
      })
    },
  }
}
</script>
<style scoped>
.me-top {
  margin: 0 24px 0 0;
  padding: 24px;
  display: flex;
  align-items: center;
  /* 在垂直方向上居中对齐 */
  border-bottom: 1px solid #D8D8D8;
}

.me-top img {
  margin-right: 20px;
}

.me-top span {
  font-family: HarmonyOS Sans SC;
  font-size: 20px;
  font-weight: bold;
  line-height: normal;
  color: #3D3D3D;
}

.me-main {
  margin: 0 24px;
  display: flex;
  align-items: center;
  /* 在垂直方向上居中对齐 */
}

.me-main-box {
  width: 50%;
  display: flex;
  align-items: center;
  /* 在垂直方向上居中对齐 */
}

.me-main-box>div,
.me-main>div>p {
  display: inline-block;
}

.me-main-title {
  font-family: HarmonyOS Sans SC;
  font-size: 16px;
  font-weight: bold;
  line-height: normal;
  font-variation-settings: "opsz" auto;
  font-feature-settings: "kern" on;
  color: #3D3D3D;
  margin-right: 30px;
}

.me-main-txt {
  font-family: HarmonyOS Sans SC;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0em;
  color: #333333;
}

.me-main-txt span {
  color: #999999;
}
</style>